// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:400,700|Playfair+Display:700');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "custom";
@import "global";
@import "header";
@import "front";
@import "quiz";
@import "result";
@import "landing-page";
@import "purchase-page";
