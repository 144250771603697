.content-wrapper {
  padding-bottom: 128px;
  padding-top: 3rem;
  line-height: 24px;

  p {
    margin-bottom: 30px;
    font-size: 15px;
  }

  .above-content-ad-space,
  .below-content-ad-space {
    .mobile {
      display: none;
    }

    .full-width {
      display: block;
    }

    @media all and (max-width: 800px) {
      .mobile {
        display: block;
      }

      .full-width {
        display: none;
      }
    }
  }
}

.inner-container {
  max-width: 1170px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.content-main {
  background: $white;
  padding: 4rem;
  border: 1px solid #eee;
  overflow: hidden;
}

.subnav-wrapper {
  padding-right: 45px;

  h3 {
    margin: 0;
    background: $brand-danger;
    color: $white;
    padding: 2rem;
    font-size: 18px;
    text-align: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background: url('../images/stripes-white.png');
      opacity: 0.5;
    }
  }
}

.subnav {
  background: $white;
  border: 1px solid #eee;
  border-top: 0;

  .menu {
    padding: 2rem;
    margin: 0;

    li {
      list-style: none;
      padding: .7rem 0;
      line-height: 1.3;
      border-bottom: 1px solid #eee;

      &:last-of-type {
        border: 0;
      }

      &.active a {
        font-weight: bold;
      }

      a {
        color: $text-color;
      }
    }
  }
}

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #e6e6e6;

  h2 {
    font-size: 16px;
    font-weight: 900;
  }
}

.checkout-page {
  .alert {
    margin-top: 2rem;
    text-align: center;

    p {
      margin-bottom: 0;
    }
  }

  .content {
    background: $white;
    padding: 4rem;
    border: 1px solid #eee;
    overflow: hidden;
  }

  .product-info {
    background: $white;
    padding: 2rem 4rem;
    border: 1px solid #eee;
    margin-bottom: 4rem;

    p {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      font-weight: bold;

      .price {
        font-weight: normal;
      }
    }
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
    max-width: 500px;

    label {
      margin-bottom: -1px;
      display: flex;
      align-items: center;
      border: 1px solid #eeeeee;
      padding: 7px 0 7px 20px ;
      background: #fff;
      //border-radius: 3px;
      //box-shadow: 5px 5px 13px rgba(0, 0, 0, 0.05);

      span {
        flex: 0 0 100px;
      }

      input {
        width: 100%;
        border: 0;
        padding-right: 20px;
        font-weight: normal;

        &::-webkit-input-placeholder {
          color: #aab7c4;
          font-weight: normal;
        }
        &::-moz-placeholder {
          color: #aab7c4;
          font-weight: normal;
        }
        &:-ms-input-placeholder {
          color: #aab7c4;
          font-weight: normal;
        }
      }

      select {
        width: 100%;
        flex: 1;
        border-style: none;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: #313b3f;
        cursor: pointer;
        background: transparent;
      }
    }
  }

  .stripe-card,
  .country {
    width: 100%;
  }

  .StripeElement {
    flex: 1;
  }

  .payment-info {
    margin-bottom: 2rem;
  }

  .pay-with-stripe {
    display: block;
    margin-top: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: $dark-blue;
    color: $white;
    padding: 12px 20px;
    border: 0;
    font-family: $font-family-serif;
    font-weight: normal;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover,
    &:focus {
      background: darken($dark-blue, 7%);
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;

      &:hover,
      &:focus {
        background: $dark-blue;
      }
    }
  }
}

.quiz-listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .quiz-cta {
    flex: 0 0 30%;
    text-align: center;
    background-color: $white;
    border-radius: 3px;
    position: relative;
    box-shadow: 3px 3px 25px rgba(0,0,0,0.15);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5rem;

    &.ad-container {
      padding: 0;
    }
  }

  .quiz-cta__title {
    background: url("../images/stripes-blue.jpg");
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    width: 100%;
    padding: 1rem 3rem;
    flex: 0 0 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

    h3{
      margin: 0;
      color: $white;
      font-size: 24px;
    }
  }

  .quiz-cta__body {
    z-index: 1;
    position: relative;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: 2rem;
    align-content: center;
    text-align: left;

    .subtitle {
      font-size: 14px;
      text-align: left;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }

  .quiz-cta__button {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    left: 2rem;
    display: block;
    color: $white;
    background: $brand-danger;
    padding: 1rem 3rem;
    font-size: 24px;
    transition: all 0.2s ease;
    font-family: $font-family-serif;

    &:hover,
    &:focus {
      background: darken($brand-danger, 7%);
      text-decoration: none;
    }
  }
}

@media all and (max-width: 991px) {
  .content-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .subnav-wrapper {
    padding: 0;
    margin-top: 4rem;
  }

  .quiz-listing {
    padding-left: 20px;
    padding-right: 20px;

    .quiz-cta {
      flex: 0 0 48%;
    }
  }
}

@media all and (max-width: 600px) {
  .quiz-listing {
    justify-content: center;

    .quiz-cta {
      flex: 0 0 80%;
    }
  }
}