.result {
  background: url('../images/stripes-white.png');

  .container-wrapper {
    position: relative;
    padding-top: 2rem;
  }

  #container {
    width: 70%;
    height: 300px;
    margin: 0 auto;
  }

  .title {
    font-size: 21px;
    font-style: italic;
    margin-bottom: 50px;
  }

  .quiz-score {
    text-align: center;
    font-size: 18px;
    color: $white;

    span {
      font-weight: bold;
    }
  }

  .price-blocks {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;

    .price-block {
      $radius: 12px;
      flex: 0 0 30%;
      position: relative;
      padding: 2rem 2rem 11.7rem;
      border-radius: 4px;
      background: $white;
      border: 1px solid #eee;
      overflow: hidden;
      box-shadow: 1px 1px 15px rgba(0,0,0,0.1);

      &::before {
        position: absolute;
        background: transparentize($gray, .9);
        top: -$radius;
        left: -$radius;
        width: $radius * 2;
        height: $radius * 2;
        display: block;
        border-radius: 50%;
        content: '';
        box-shadow:
                0 0 0 $radius * 2 transparentize($gray, .95);
      }

      &:last-of-type::before {
        background: transparentize($gray, .8);
        box-shadow:
                0 0 0 $radius * 2 transparentize($gray, .95),
                0 0 0 $radius * 4 transparentize($gray, .95),
                0 0 0 $radius * 6 transparentize($gray, .95);
      }

      &.most-popular {
        border: 2px solid $brand-primary;
        box-shadow: 4px 4px 25px rgba(0,0,0,0.4);
        position: relative;
        top: -20px;

        &::before {
          background: transparentize($brand-primary, .85);
          box-shadow:
                  0 0 0 $radius * 2 transparentize($brand-primary, .95),
                  0 0 0 $radius * 4 transparentize($brand-primary, .95);
        }

        .tagline {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0 auto;
          padding: .5rem 2rem;
          border-bottom-left-radius: 4px;
          background: $brand-primary;
          color: $white;
          font-size: 12px;
          font-weight: 500;
        }

        h3,
        .price {
          color: $brand-primary;
        }
      }

      .price {
        font-size: 24px;
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          padding: 1rem .5rem;
          border-bottom: 1px solid #eee;
          font-size: 13px;
        }

        .text-bold {
          font-weight: 500;
        }
      }

      .buy-button {
        background: $dark-blue;
        padding: 1rem 3rem;
        display: inline-block;
        color: $white;
        border-radius: 2px;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        width: 70%;
        margin: 0 auto;
        box-shadow: 2px 2px 9px transparentize($dark-blue, .7);
        transition: all 0.2s ease;

        &:hover,
        &:focus {
          text-decoration: none;
          background: darken($dark-blue, 7%);
          box-shadow: 1px 1px 4px transparentize($dark-blue, .7);
        }
      }
    }
  }

  .encryption-notice {
    clear: both;
  }

  @media all and (max-width: 767px) {
    .price-blocks {
      flex-wrap: wrap;

      .price-block {
        flex: 0 0 80%;
        margin-bottom: 2rem;

        &.most-popular {
          top: 0;
          box-shadow: 1px 1px 15px rgba(0,0,0,0.1);
        }
      }
    }
  }
}

.additional-quizzes {
  margin-bottom: 4rem;

  .cta-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .additional-quiz {
    flex: 0 0 100%;
    padding: 2rem;
    position: relative;
    border: 1px solid #eee;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 2rem;

    .inner {
      height: 100%;
      align-items: center;
      display: flex;

      p {
        margin-bottom: 0;
        padding-right: 10px;
      }
    }

    h3 {
      margin: 0 0 10px 0;
      flex: 0 0 70%;
    }

    a {
      flex: 0 0 30%;
      text-align: center;
      display: block;
      color: #fff;
      background: #e63946;
      padding: 1rem 3rem;
      font-size: 16px;
      transition: all 0.2s ease;
      font-family: "Playfair Display", serif;
    }
  }
}

.results-wrapper {
  .result {
    padding: 2rem 4rem 4rem 6rem;
    margin-bottom: 2rem;
    background: $white;
    border-radius: 4px;
    position: relative;
    border: 1px solid #eee;

    > .glyphicon {
      position: absolute;
      padding: 1rem;
      top: -1rem;
      right: -1rem;
      color: $white;
      border-radius: 3px;
      box-shadow: 1px 1px 13px rgba(0, 0, 0, 0.05);
      background: $brand-danger;
    }

    &.correct > .glyphicon {
      background: $brand-success;
    }

    .question--number {
      position: absolute;
      top: 2rem;
      left: 2rem;
      font-weight: bold;
      font-size: 18px;
      color: #cacaca;
    }

    .question--title {
      margin-bottom: 30px;

      p {
        margin-bottom: 0;
      }
    }

    .question--explanation {
      margin-left: -6rem;
      margin-right: -4rem;
      padding: 3rem 4rem 0 6rem;
      border-top: 1px solid #eee;
      margin-top: 4rem;

      br {
        display: none;
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    .question--tip {
      margin-top: 2rem;
      padding: 2rem;
      border: 1px solid $brand-primary;
      color: $brand-primary;
      background: transparentize(lighten($brand-primary, 20%), .9);

      br {
        display: none;
      }

      h4 {
        margin-top: 0;
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    .answers {
      list-style: none;
      padding: 0;

      li {
        padding: 1rem;
        border: 1px solid #cacaca;
        border-radius: 4px;
        margin-bottom: 1rem;

        > .glyphicon {
          margin-right: 1rem;
        }

        &.selected {
          background: rgba($brand-danger, 0.1);
          color: $brand-danger;
          border: 1px solid $brand-danger;
        }

        &.correct {
          background: rgba($brand-success, 0.1);
          color: $brand-success;
          border: 1px solid $brand-success;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media all and (max-width: 600px) {
  .additional-quizzes {
    .additional-quiz {
      .inner {
        flex-wrap: wrap;

        p {
          margin-bottom: 10px;
          padding-right: 0;
        }
      }

      h3 {
        flex: 0 0 100%;
      }

      a {
        flex: 0 0 100%;
      }
    }
  }
}