.header {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: $dark-blue url('../images/hero.jpg') no-repeat center center;
  background-size: cover;
  border-bottom: 0;
  left: 0;
  top: 0;
  width: 100%;

  .nav-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    nav {
      flex: 1;
      text-align: right;
    }

    .home__link {
      flex: 0 0 auto;
    }
  }

  nav {
    ul {
      margin: 0;

      li {
        display: inline;
        list-style: none;
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }

        a {
          font-size: 16px;
          color: $white;
          text-transform: uppercase;
        }
      }
    }
  }

  .mobile-trigger {
    padding: 8px;
    background: #fff;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 40px;
    cursor: pointer;
    z-index: 102;
    display: none;

    span {
      display: block;
      width: 100%;
      height: 3px;
      background: $brand-primary;
      margin-bottom: 4px;
      transform: translateX(0px);
      transition: all 0.15s ease;

      &.last {
        margin-bottom: 0;
      }
    }

    &.open {
      .first,
      .last {
        transform: translateX(-2px);
      }

      .middle {
        transform: translateX(2px);
      }
    }
  }

  .home__link {
    font-size: 16px;
    padding: 1rem;
    color: white;
    transition: all 0.2s ease;
    text-transform: uppercase;
  }

  .sub-heading {
    text-align: center;
    color: $white;
    font-size: 18px;
  }

  @media all and (max-width: 991px) {
    text-align: center;

    .mobile-trigger {
      display: block;
    }

    .home__link {
      margin: 0 auto;
      display: inline-block;
      margin-bottom: 1rem;
    }

    nav {
      float: none;
      text-align: center;
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0px;
      padding: 5rem;
      top: 0;
      z-index: -1;
      opacity: 0;
      background: $white;
      transition: opacity 0.2s ease, z-index 0.2s ease 0.2s;

      ul {
        position: absolute;
        top: 50%;
        transform: translateY(-30%);
        left: 0;
        right: 0;
        padding: 5rem;
        transition: all 0.2s ease;

        li {
          display: block;
          margin: 0 0 20px 0;

          &:hover,
          &:focus {
            ul.sub-nav {
              transform: none;
              transition: none;
            }
          }

          i {
            display: inline-block;
            padding-left: 20px;
            color: $brand-primary;

            &::before {
              display: block;
              transform: rotateZ(0deg);
            }

            &.active::before {
              transform: rotateZ(180deg);
            }
          }

          a {
            display: inline-block !important;
            color: $brand-primary;
          }

          ul.sub-nav {
            display: none;
            opacity: 1;
            z-index: 1;
            position: relative;
            background: transparent;
            padding: 20px 0 0 0;
            margin: 0;
            box-shadow: none;
            transform: none;
            left: 0;

            &::before {
              display: none;
            }

            &:hover,
            &:focus {
              transform: none;
              transition: none;
            }

            li {
              margin: 0;

              a {
                color: $brand-primary;
              }
            }
          }
        }
      }

      &.open {
        opacity: 1;
        z-index: 100;
        transition: all 0.2s ease;

        ul {
          transform: translateY(-50%);
          transition: all 0.2s ease;
        }
      }
    }
  }
}