.home-ctas {
  background: #333 url('../images/hero.jpg') no-repeat center center;
  background-size: cover;
  padding-top: 3rem;
  padding-bottom: 2rem;
  height: auto;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.front {
  .header {
    position: absolute;
    background: transparent;
  }

  .content-wrapper {
    padding-top: 0;
  }
}

.home {
  padding: 0;

  h1 {
    text-align: center;
    color: $white;
    font-weight: 500;
    max-width: 700px;
    margin: 6rem auto 5rem;
  }

  .teaser-copy {
    padding: 270px 0 3rem;
    padding: calc(240px + 3rem) 0 3rem;
  }

  .tagline {
    text-align: center;
    font-weight: 400;
    max-width: 500px;
    margin: 0 auto;
  }
}

.quiz-cta-container {
  display: flex;
  flex: 0 0 100%;
  justify-content: space-between;
  padding: 4rem 1rem;
  margin-bottom: -240px;

  .quiz-cta {
    flex: 0 0 30%;
    text-align: center;
    padding: 0;
    height: 280px;
    background-color: $white;
    border-radius: 3px;
    position: relative;
    box-shadow: 3px 3px 25px rgba(0,0,0,0.15);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;

    &.ad-container {
      padding: 0;
    }
  }

  .quiz-cta__title {
    background: url("../images/stripes-blue.jpg");
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    width: 100%;
    padding: 1rem 3rem;
    flex: 0 0 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

    h2{
      margin: 0;
      color: $white;
      font-size: 24px;
    }
  }

  .quiz-cta__body {
    z-index: 1;
    position: relative;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: 0 2rem;
    align-content: center;
    text-align: left;

    .subtitle {
      font-size: 14px;
      text-align: left;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }

  .quiz-cta__button {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    left: 2rem;
    display: block;
    color: $white;
    background: $brand-danger;
    padding: 1rem 3rem;
    font-size: 24px;
    transition: all 0.2s ease;
    font-family: $font-family-serif;

    &:hover,
    &:focus {
      background: darken($brand-danger, 7%);
      text-decoration: none;
    }
  }

  .quiz-cta__icon {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 20px;
    text-align: center;
    font-size: 240px;
    color: #e1e1e1;
    opacity: .5;


    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      color: #f6f6f6;
    }
  }

  @media all and (max-width: 991px) {
    .quiz-cta {
      flex: 0 0 45%;

      &.ad-container {
        display: none;
      }
    }
  }

  @media all and (max-width: 615px) {
    flex-wrap: wrap;

    .quiz-cta {
      flex: 0 0 100%;
      margin-bottom: 2rem;
    }
  }
}

.article {
  display: flex;
  flex-wrap: wrap;
  background: $white;
  box-shadow: 3px 3px 25px rgba(0,0,0,0.15);

  &:not(:last-of-type) {
    margin-bottom: 3rem;
  }

  &__first .article--image {
    background-image: url('../images/houses.jpg');
  }

  &__second .article--image {
    background-image: url('../images/real-estate-basics.jpg');
  }

  &__third .article--image {
    background-image: url('../images/tips-for-prep.jpg');
  }
}

.article--image {
  flex: 0 0 30%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.article--teaser {
  flex: 0 0 70%;
  padding: 4rem;
  border-top: 5px solid $brand-danger;

  h3 {
    margin-top: 0;

    a {
      color: $text-color;
    }
  }
}

.article--button {
  display: inline-block;
  color: $white;
  background: $brand-danger;
  padding: 1rem 3rem 1.2rem;
  font-size: 24px;
  transition: all 0.2s ease;
  font-family: $font-family-serif;

  &:hover,
  &:focus {
    background: darken($brand-danger, 7%);
    text-decoration: none;
    color: $white;
  }
}

@media all and (max-width: 767px) {
  .home {
    .teaser-copy {
      padding: 600px 15px 3rem;
      padding: calc(570px + 3rem) 15px 3rem;
    }
  }

  .quiz-cta-container {
    margin-bottom: -570px;

    .quiz-cta {
      margin-bottom: 5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@media all and (max-width: 615px) {
  .article--image {
    flex: 0 0 100%;
    height: 150px;
  }

  .article--teaser {
    flex: 0 0 100%;
  }
}