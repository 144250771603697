.purchase-page {
  background: #f6f6f6 url("../images/stripes-white.png");

  .book-image {
    margin-top: 3rem;
    margin-bottom: 4rem;
    text-align: center;

    img {
      margin: 0 auto;
      width: 80%;
    }
  }

  .download-btn {
    background: $brand-primary;
    color: $white;
    font-weight: bold;
    padding: 2rem;
    border-radius: 4px;
    display: inline-block;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      background: darken($brand-primary, 10%);
      text-decoration: none;
    }
  }

  .quiz-score {
    text-align: center;
    font-size: 18px;
    color: $white;

    span {
      font-weight: bold;
    }
  }
}