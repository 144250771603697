html, body {
  background: $white url("../images/stripes-white.png");
  color: #636b6f;
  font-weight: 400;
  height: 100vh;
  margin: 0;

  &.nav-open {
    overflow: hidden;
  }
}

body {
  min-height: 100%;
  height: auto;
  position: relative;
}

img {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-serif;
}

.page-title {
  max-width: 700px;
  margin: 7rem auto 3rem;
  color: $white;
  text-align: center;
}